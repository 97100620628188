





























































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import RJTextField from '@/components/shared/custom-vuetify/RJTextField.vue';
import WorkSession from '@/models/WorkSession';
import {jobStoreActions} from '@/stores/job.store';
import {namespace} from 'vuex-class';

const JobStore = namespace('job');

interface Thumbnail {
  id?: string;
  loaded: boolean;
  url: string;
}

interface Img {
  blob: Blob;
}

@Component({
  components: {
    RJTextField,
  },
})
export default class CommentTabComponent extends Vue {

  @JobStore.Action(jobStoreActions.LOAD_WORK_SESSION_IMAGE_ACTION)
  public loadWorkSessionImage!: (payload: { workSessionId: string, imageId: string, thumbnail: boolean }) => Promise<Img>;

  @Prop()
  private selectedWorksession!: WorkSession;

  // Image section
  /**
   * array the images as thumbnail for faster loading times
   */
  public thumbnails: Thumbnail[] = [];


  /**
   * current Image in the Dialog
   */
  public selectedImage: Thumbnail | null = null;


  // for faster Image loading in the dialog

  public selectedImageKey: number = 0;

  /**
   * Cache of full-loaded images
   */
  public imageCache: Thumbnail[] = [];

  public imageDialog: boolean = false;
  public readonly THUMBNAIL_SIZE: number = 100;

  public mounted() {
    this.loadThumbNails();
  }

  @Watch('selectedWorksession')
  public onWorkSessionChange() {
    this.loadThumbNails();
  }

  public async loadThumbNails() {
    if (this.selectedWorksession?.hasImages) {
      this.thumbnails = await Promise.all(this.selectedWorksession.images.map(async (image): Promise<Thumbnail> => {
        try {
          const apiImage = await this.loadWorkSessionImage(
              { workSessionId: this.selectedWorksession.id, imageId: image.id, thumbnail: true});
          const fileObject = URL.createObjectURL(apiImage.blob);

          return {
            id: image.id,
            loaded: true,
            url: fileObject,
          };
        } catch (e) {
          return {
            loaded: false,
            url: '',
          };
        }
      }));

      this.$forceUpdate();
    }
  }

  public async openSelectedImage(image: Thumbnail) {
    const apiImage = await this.loadWorkSessionImage(
        {workSessionId: this.selectedWorksession.id, imageId: image.id!, thumbnail: false });
    const fileObject = URL.createObjectURL(apiImage.blob);

    this.selectedImage = {
      id: image.id,
      loaded: true,
      url: fileObject,

    };
    this.imageCache[this.selectedImageKey] = this.selectedImage;
    this.imageDialog = true;
  }

  private changeImageButtonClick(isRight: boolean) {
    isRight ? this.selectedImageKey++ : this.selectedImageKey--;
    if (this.selectedImageKey in this.imageCache) {
      this.selectedImage = this.imageCache[this.selectedImageKey];
    } else {
      this.openSelectedImage(this.thumbnails[this.selectedImageKey]);
    }
  }
}
