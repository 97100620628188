





































































import { Component, Prop, Vue } from 'vue-property-decorator';
import ACTT from '../../models/ACTT';
import WorkSession from '@/models/WorkSession';
import Task from '@/models/Task';

@Component({
    components: {
        SessionACTTDetailsComponent: () => import(
            /* webpackChunkName: "SessionACTTDetailsComponent" */
            '@/components/job/sessionACTT/SessionACTTDetails.component.vue'),
    },
})
export default class ActtComponent extends Vue {
    @Prop()
    private acttItem!: ACTT;

    @Prop()
    private worksession!: WorkSession;

    @Prop({ default: false })
    private deletable!: boolean;

    private expanded: boolean = false;
    // default icon path
    private path = {url: require('./../../assets/images/taskIcons/default_color.png')};

    private mounted() {
        // set icon path for colorSchema
        if (this.acttItem && (this.acttItem.task as Task).colorSchema && (this.acttItem.task as Task).colorSchema!.length > 0) {
            // a path can't handle # so wie have to cut it out
            const colorCode = (this.acttItem.task as Task).colorSchema!.replace('#', '');

            // Try to retrieve the correct image for this colorschema, on error use the default color
            try {
              this.path = {url: require(`./../../assets/images/taskIcons/${colorCode}.png`)};
            } catch (e) {
              this.path = {url: require('./../../assets/images/taskIcons/default_color.png')};
            }
        }
    }

    /**
     * expands or closes the item
     */
    private onActtItemClick() {
        if (this.acttItem.sessionACTT) {
            this.expanded = !this.expanded;
        }
    }

    /**
     * returns color
     * @param task
     */
    private getColor(task: any): string {
        return (task.colorSchema ? task.colorSchema : this.$colorHandler.getThemeColor('task-default'));
    }

    /**
     * delete Event will be emitted on trash clicked
     * @private
     */
    private onDeleteClicked() {
      this.$emit('delete', this.acttItem);
    }
}
