















































































































































































































































































import {Component, Vue, Watch} from 'vue-property-decorator';
import {namespace} from 'vuex-class';
import User from '@/models/User';
import {convertMs, getFormattedTime} from '@/helper/JobTimeHelper';
import BaseDataCellComponent from '@/components/shared/BaseDataCell.component.vue';
import CleanTime from '@/models/CleanTime';

const UserStore = namespace('user');

@Component({
  components: {BaseDataCellComponent},
})
export default class UserBaseDataComponent extends Vue {

  @UserStore.Getter('activeUser')
  private _user!: User;
  @UserStore.Action('setUserStatusAction')
  private setUserStatusAction!: (payload: { id: string, active: boolean, preview?: boolean, force?: boolean }) => Promise<User>;

  get user(): User {
    return this._user;
  }
  private sum: number = 0;

  private addToSum(cleanTime: CleanTime) {
    const time = cleanTime.times[0];
    if (time && time.duration) {
      this.sum += time.duration / (1000 * 60 * 60);
      this.sum = Math.round(this.sum * 100) / 100;
    }
  }

  public showChangeStatusDialog = false;
  public showDeactivationDialog = false;

  public onStatusChange() {
    this.showChangeStatusDialog = false;
    if (this.user.active === true) {
      this.showDeactivationDialog = true;
    } else {
      this.changeUserStatus();
    }
  }

  public async changeUserStatus() {
    this.showDeactivationDialog = false;
    try {
      const params = {
        id: this.user.id!,
        active: !this.user.active!,
        force: true,
      };
      await this.setUserStatusAction(params);

      this.user.active ?
          this.$notifySuccessSimplified('USER_MANAGE.NOTIFICATIONS.USER_DEACTIVATE') :
          this.$notifySuccessSimplified('USER_MANAGE.NOTIFICATIONS.USER_ACTIVATE');
    } catch (error) {
      // Reset Activate State to False, because some error happened
      this.user.active = false;

      // Show Correct error Message
      if (error.status === 402) {
        this.$notifyErrorSimplified('USER_MANAGE.NOTIFICATIONS.QUOTA_EXCEEDED');
      } else {
        this.$notifyErrorSimplified('GENERAL.NOTIFICATIONS.GENERAL_ERROR');
      }
    }
  }

  public getCommentData() {
    return {
      date: this.$options.filters!.toDate(this.user.createdAt),
      time: this.$options.filters!.toZoneTime(this.user.createdAt),
    };
  }

  /**
   * Calculates a and returns a String representation for the clean time duration
   */
  public getCleanTimeDuration(cleanTime: CleanTime): string {
    let timeString = '';

    for (const times of cleanTime.times) {
      const duration = convertMs(times.duration);

      // If minutes are over 60, another hour is added to the hour
      if (times.minute >= 60) {
        times.hour++;
        times.minute -= 60;
      }

      // checks if the endTime is over 24 hours and if so the hours are reduced by 24 (e.g. 26:00 is changed to 02:00)
      const startTimeHours = times.hour;
      const startTimeMinutes = times.minute;
      let endTimeHours = startTimeHours + duration.hour;
      let endTimeMinutes = startTimeMinutes + duration.minute;

      if (endTimeMinutes >= 60) {
        endTimeHours += 1;
        endTimeMinutes -= 60;
      }
      if (endTimeHours >= 24) {
        endTimeHours -= 24;
      }

      timeString += `${getFormattedTime(startTimeHours, startTimeMinutes)} - ${getFormattedTime(endTimeHours, endTimeMinutes)} ${cleanTime.times.length > 1 ? '<br />' : ''}`;
    }

    return timeString;
  }

@Watch('user', {deep: true})
 private watchUser() {
   this.sum = 0 ;
   this.user.availableAtCleanTimes?.forEach((cleanTime) => {
     if (cleanTime.byWeekDay != null) {
       this.addToSum(cleanTime);
      }
    });
  }

}
