































































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import WorkSession from '@/models/WorkSession';
import Job from '@/models/Job';
import {namespace} from 'vuex-class';
import {jobStoreActions} from '@/stores/job.store';
import moment from 'moment';
import CleanTimeOccurrence from '@/models/CleanTimeOccurrence';
import RJAutocomplete from '@/components/shared/custom-vuetify/RJAutocomplete.vue';
import User from '@/models/User';

const JobStore = namespace('job');

@Component({
  components: {
    RJAutocomplete,
    RJTextField: () => import(
        '@/components/shared/custom-vuetify/RJTextField.vue'),
    RJSelect: () => import(
        '@/components/shared/custom-vuetify/RJSelect.vue'),
    MenuWithPicker: () => import(
        '@/components/shared/MenuWithPicker.component.vue'),
  },
})
export default class WorkSessionManageComponent extends Vue {
  @Prop({default: () => []})
  public workSessions!: WorkSession[];

  @Prop({default: () => new Job()})
  public job?: Job;

  @JobStore.Action(jobStoreActions.CREATE_WORK_SESSIONS_ACTION)
  private createWorkSession!: (workSession: WorkSession) => Promise<WorkSession>;

  @JobStore.Action(jobStoreActions.UPDATE_WORK_SESSIONS_ACTION)
  private updateWorkSession!: (workSession: WorkSession) => Promise<WorkSession>;

  public get editMode() {
    return !!this.currentWorkSession.id;
  }

  @Prop({default: () => new WorkSession()})
  public workSessionToEdit!: WorkSession;

  public currentWorkSession: WorkSession = new WorkSession();

  private workSessionUser: string = '';

  private get usersWithoutWorkSession(): User[] | undefined {
    return this.job?.cleanTime.plannedUsers.filter((user) =>
        !this.job?.workSessions.some((session) => (session.user as User).id === (user as User).id)) as User[];
  }
  private dateMinMax: any = undefined;
  /**
   *  Time variables
   */
  private date: string = '';
  private startTime: string = '';
  private endTime: string = '';

  public mounted() {
    if (!this.editMode && this.job && this.job.cleanTime.plannedUsers.length > 1) {
      this.workSessionUser = this.usersWithoutWorkSession![0].id as string;
    }
    this.watchWorkSession();
    this.dateMinMax = {
      // work cannot be done before job starts
      min: moment(this.job!.cleanTimeOccurrence.start).startOf('day').format(this.$t('GENERAL.DATE_FORMATS.PICKER_DATE').toString()),
      // work cannot be done in the future
      max: moment().format(this.$t('GENERAL.DATE_FORMATS.PICKER_DATE').toString()),
    };
  }

  private async submit() {
    try {
      if (!this.workSessionUser && !this.editMode) {
        return this.$notifyInfoSimplified('TIMETRACKING.NOTIFICATIONS.NO_USER_SELECTED');
      }
      if (!(this.startTime && this.endTime) || this.startTime === this.endTime) {
        return this.$notifyInfoSimplified('TIMETRACKING.NOTIFICATIONS.INVALID_TIME');
      }

      const workSessionCopy = this.currentWorkSession.copy() as WorkSession;

      // set start- and endTime
      const timeWithDate = moment(this.date).format(this.$t('GENERAL.DATE_FORMATS.PICKER_DATE').toString()) + ' ' + this.startTime;
      workSessionCopy.startTime = moment(timeWithDate).toISOString();
      workSessionCopy.endTime = moment(timeWithDate).add(workSessionCopy.duration).toISOString();

      // endTime is on the next day if startTime > endTime
      if (moment(workSessionCopy.startTime).isAfter(moment(workSessionCopy.endTime))) {
        workSessionCopy.endTime = moment(workSessionCopy.endTime).add(1, 'days').toISOString();
      }

      if (this.editMode) {
        await this.updateWorkSession(workSessionCopy);
      } else {
        workSessionCopy.userId = this.workSessionUser;
        workSessionCopy.cleanTimeId = this.job?.cleanTime.id!;
        workSessionCopy.cleanTimeOccurrence = this.job?.cleanTimeOccurrence.start;
        await this.createWorkSession(workSessionCopy).then((session) => {
          workSessionCopy.id = session.id;
        });
      }
      this.$notifySuccessSimplified(this.editMode ? 'TIMETRACKING.NOTIFICATIONS.EDIT_WORK_SESSION'
          : 'TIMETRACKING.NOTIFICATIONS.CREATE_WORK_SESSION');
      this.$emit('worksession-update');
    } catch (e) {
      this.$notifyErrorSimplified('GENERAL_ERROR');
    }
  }

  private cancel() {
    this.$emit('cancel');
  }

  public getDurationHumanized(duration: number) {
    const d = moment.duration(duration);
    return `${d.hours()} ${this.$t('GENERAL.UNITS_SHORT.HOUR')}  ${(d.minutes() + '').padStart(2, '0')} ${this.$t('GENERAL.UNITS_SHORT.MINUTES')}.`;
  }

  /**
   * function to find the worksession if it exists, and autofill the inputs
   */
  @Watch('endTime')
  @Watch('startTime')
  // watch the time
  public inTimeChange() {
    // set the duration
    if (this.startTime && this.endTime) {
      this.currentWorkSession.setTimes(this.startTime, this.endTime, this.job ? this.job.cleanTimeOccurrence : new CleanTimeOccurrence());
    }
  }

  @Watch('workSessionUser')
  private watchWorkSession() {
    let session: any;
    if (this.workSessionToEdit.id) {
      session = this.workSessionToEdit.copy();
    } else {
      session = this.workSessions.find((wSession) => typeof wSession.user === 'string' ?
          wSession.user === this.workSessionUser : wSession.user!.id === this.workSessionUser);
    }
    if (!session) {
      session = new WorkSession();
    }
    this.currentWorkSession = session.copy() as WorkSession;
    if (this.currentWorkSession.id) {
      this.date = this.currentWorkSession.startTime;
      this.startTime = moment(this.currentWorkSession.startTime).format('HH:mm');
      this.endTime = moment(this.currentWorkSession.endTime).format('HH:mm');
      this.currentWorkSession.userId = this.currentWorkSession.userId;
    }
    this.inTimeChange();
  }
}
